@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");

li {
  background: url(images/diamond.svg) no-repeat left top; /* <-- change `left` & `top` too for extra control */
  padding-left: 25px;
  padding-top: =2px;
  /* reset styles (optional): */
  list-style: none;
  margin: 0;
}
